import contactbutton from '@/components/ContactButton.vue'
import { mapState } from 'vuex'

export default {
    name: 'ContactForm',
    components: { contactbutton },
    data: () => ({
        modmade: false,
        moddedfields: {},
        deponly: '',
        miconly: '',
        isassistsave: false,
        assistremove: false,
        assistremoveitem: {},
        newan: '',
        newae: '',
        newat: '',
        savemsg: ''
    }),
    async mounted() {
        this.refreshVals(false)

        if (this.$route.params.dep && this.$route.params.mic) {
            await this.$nextTick(() => {
                this.depmic.selected = this.$route.params.dep + '-' + this.$route.params.mic
                this.getDM()
            })
        }

        this.dmstatus.savemsg = false
        this.dmstatus.savemsgerr = false
    },
    computed: {
        ...mapState('Sgs', ['msg', 'msgcolor', 'msgtimeout', 'msgcpos', 'msgtpos']),
        showmsg: { get() { return this.$store.getters['Sgs/getShowMsg'] }, set() { this.$store.commit('Sgs/RESETMSG') } },
        user: { get() { return this.$store.getters['Sgs/getUser'] } },
        depmic: { get() { return this.$store.getters['Contact/getDepMic'] }, set(val) { this.$store.commit('Contact/SET_DEPMIC', val) } },
        dmstatus: { get() { return this.$store.getters['Contact/getDmStatus'] } },
        depmiclist: { get() { return this.$store.getters['Contact/getDmList'] }, set(val) { this.$store.commit('Contact/SET_DMLIST', val) } },
        depmictotaldms: { get() { return this.$store.getters['Contact/getDmTotalAss'] } },
        depmicmissinglist: { get() { return this.$store.getters['Contact/getDmMissingList'] } },
        depmicmissingfields: { get() { return this.$store.getters['Contact/getDmMissingFields'] } },
        depmicmissingstores: { get() { return this.$store.getters['Contact/getDmMissingStores'] } },
        assistanttypes: { get() { return this.$store.getters['Contact/getAssistantTypes'] } },
        dm: { get() { return this.$store.getters['Contact/getDm'] } }
    },
    methods: {
        refreshVals(getsingle) {
            this.$store.dispatch('Contact/fetchDmList', null, { root: true })
            this.$store.dispatch('Contact/fetchMissing', null, { root: true })

            if (getsingle) {
                /* --- we assume depmic.selected is set --- */
                this.getDM()
            }
        },
        changeDM() {
            let sendthis = this.depmic.selected.split('-')

            this.$router.push({ path: '/admin/contact/' + sendthis[0] + '/' + sendthis[1], replace: true })
        },
        getDM() {
            this.$store.dispatch('Contact/fetchSingle', null, { root: true })
                .then(() => {
                    this.$store.dispatch('Contact/fetchMissing', null, { root: true })

                    if (this.depmic.active === 0) {
                        this.$store.commit('Sgs/SET_MSG', [true, 'This D/M is flagged as INACTIVE in the database', 'red', '-1', 'c'])
                    }
                })
        },
        getMissing() {
            //this.deptmic.selected = this.deptmic.missingstoresel

            //this.goget(e)
        },
        trackMods(o) {
            this.moddedfields[o.sys] = o
            this.modmade = true
        },
        goSave(desc) {
            this.deponly = this.depmic.selected.split('-')[0]
            this.miconly = this.depmic.selected.split('-')[1]
            this.savemsg = desc

            this.$store.commit('Contact/SET_UPDATESAVEINFO', true)
        },
        dmSave(whichone) {
            let key = '',
                fields = '',
                savefields = ''

            for (key in this.moddedfields) {
                fields = fields + this.moddedfields[key].name + '\n'
                savefields = savefields + key + '|'
            }

            if (this.isassistsave) {
                this.$store.dispatch('Contact/saveAssistant', { whichone: 'assistant', parts: { name: this.newan, email: this.newae, type: this.newat }, applyto: whichone }, { root: true })
                    .then(() => {
                        this.refreshVals(true)
                        this.newan = ''
                        this.newae = ''
                        this.newat = ''
                    })
            } else {
                if (this.modmade) {
                    if (whichone === 'all') {
                        this.$store.dispatch('Contact/saveContact', { whichone: 'contact', savefields: savefields.substr(0, savefields.length - 1) }, { root: true })
                    } else {
                        this.$store.dispatch('Contact/saveContact', { whichone: 'contact', savefields: false }, { root: true })
                    }
                }
            }

            if (this.assistremove) {
                this.$store.dispatch('Contact/saveAssistant', { whichone: 'removeassistant', item: this.assistremoveitem, applyto: whichone }, { root: true })
                    .then(() => {
                        this.refreshVals(true)
                    })
            }

            this.isassistsave = false
            this.assistremove = false
            this.modmade = false
            this.$store.commit('Contact/SET_UPDATESAVEINFO', false)
        },
        clearupdate() {
            this.dm.selectupdate = false
            this.dm.readyforupdate = true
        },
        togglebutton() {
            if (this.dm.selectedupdate.length) {
                this.dm.readyforupdate = false
            } else {
                this.dm.readyforupdate = true
            }
        },
        putupdateval() {
            this.depmic[this.dm.whatfield] = this.dm.selectedupdate
            this.trackMods({ sys: this.dm.whatfield, name: this.dm.whatfield })
            this.clearupdate()
        },
        addAssistant() {
            if (!this.newan || !this.newae || !this.newat) {
                this.$store.commit('Sgs/SET_MSG', [true, 'Please ensure every value is provided', 'red', '-1', 'c'])
            } else {
                this.isassistsave = true
                this.goSave('addition')
            }
        },
        removeAssistant(item) {
            this.assistremove = true
            this.assistremoveitem = item

            this.goSave('removal of ' + item.name)
        }
    }

}